export const databaseRepair = [
  {
    model: 'iPhone 6',
    service: {
      displayOriginal: 0,
      displayAnalog: 1400,
      glassReplaceAnalog: 0,
      batteryOriginal: 1500,
      batteryAnalog: 700,
      cameraMainOriginal: 1900,
      cameraFrontOriginal: 1000,
      motherboard: 0,
      cleaningAudioChannelsMesh: 350,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 1000,
      restoringTightness: 0,
      buttonPower: 750,
      speaker: 750,
      jackHeadphones: 750,
      body: 1000,
      chargingCable: 750,
      microphone: 750,
      glassCameraAnalog: 1300,
    }
  },
  {
    model: 'iPhone 6 Plus',
    service: {
      displayOriginal: 0,
      displayAnalog: 1500,
      glassReplaceAnalog: 0,
      batteryOriginal: 1500,
      batteryAnalog: 700,
      cameraMainOriginal: 1900,
      cameraFrontOriginal: 1000,
      motherboard: 0,
      cleaningAudioChannelsMesh: 350,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 1000,
      restoringTightness: 0,
      buttonPower: 750,
      speaker: 750,
      jackHeadphones: 750,
      body: 1000,
      chargingCable: 750,
      microphone: 750,
      glassCameraAnalog: 1300,
    }
  },
  {
    model: 'iPhone 6S',
    service: {
      displayOriginal: 5300,
      displayAnalog: 1400,
      glassReplaceAnalog: 0,
      batteryOriginal: 1500,
      batteryAnalog: 700,
      cameraMainOriginal: 1500,
      cameraFrontOriginal: 1200,
      motherboard: 0,
      cleaningAudioChannelsMesh: 350,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 5300,
      buttonHomeAnalog: 1000,
      restoringTightness: 200,
      buttonPower: 900,
      speaker: 900,
      jackHeadphones: 900,
      body: 1000,
      chargingCable: 900,
      microphone: 1200,
      glassCameraAnalog: 1300,
    }
  },
  {
    model: 'iPhone 6S Plus',
    service: {
      displayOriginal: 5900,
      displayAnalog: 1900,
      glassReplaceAnalog: 0,
      batteryOriginal: 1500,
      batteryAnalog: 700,
      cameraMainOriginal: 2800,
      cameraFrontOriginal: 1200,
      motherboard: 0,
      cleaningAudioChannelsMesh: 350,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 5900,
      buttonHomeAnalog: 1000,
      restoringTightness: 200,
      buttonPower: 900,
      speaker: 900,
      jackHeadphones: 900,
      body: 1000,
      chargingCable: 900,
      microphone: 1200,
      glassCameraAnalog: 1300,
    }
  },
  {
    model: 'iPhone 7',
    service: {
      displayOriginal: 4950,
      displayAnalog: 1600,
      glassReplaceAnalog: 0,
      batteryOriginal: 1500,
      batteryAnalog: 700,
      cameraMainOriginal: 1900,
      cameraFrontOriginal: 1900,
      motherboard: 0,
      cleaningAudioChannelsMesh: 350,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 5000,
      buttonHomeAnalog: 850,
      restoringTightness: 200,
      buttonPower: 1000,
      speaker: 1000,
      jackHeadphones: 0,
      body: 1500,
      chargingCable: 1800,
      microphone: 0,
      glassCameraAnalog: 1300,
    }
  },
  {
    model: 'iPhone 7 Plus',
    service: {
      displayOriginal: 5600,
      displayAnalog: 2200,
      glassReplaceAnalog: 0,
      batteryOriginal: 1500,
      batteryAnalog: 700,
      cameraMainOriginal: 2800,
      cameraFrontOriginal: 1900,
      motherboard: 0,
      cleaningAudioChannelsMesh: 350,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 6000,
      buttonHomeAnalog: 850,
      restoringTightness: 200,
      buttonPower: 1000,
      speaker: 1000,
      jackHeadphones: 0,
      body: 1500,
      chargingCable: 1800,
      microphone: 0,
      glassCameraAnalog: 1300,
    }
  },
  {
    model: 'iPhone 8',
    service: {
      displayOriginal: 5300,
      displayAnalog: 1900,
      glassReplaceAnalog: 1500,
      batteryOriginal: 1500,
      batteryAnalog: 700,
      cameraMainOriginal: 2900,
      cameraFrontOriginal: 1900,
      motherboard: 0,
      cleaningAudioChannelsMesh: 350,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 5600,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 1300,
      jackHeadphones: 0,
      body: 2000,
      chargingCable: 1800,
      microphone: 1300,
      glassCameraAnalog: 1300,
    }
  },
  {
    model: 'iPhone 8 Plus',
    service: {
      displayOriginal: 6100,
      displayAnalog: 2500,
      glassReplaceAnalog: 1500,
      batteryOriginal: 1500,
      batteryAnalog: 700,
      cameraMainOriginal: 2600,
      cameraFrontOriginal: 1900,
      motherboard: 0,
      cleaningAudioChannelsMesh: 350,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 5900,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 1300,
      jackHeadphones: 0,
      body: 2000,
      chargingCable: 1800,
      microphone: 1300,
      glassCameraAnalog: 0,
    }
  },
  {
    model: 'iPhone X',
    service: {
      displayOriginal: 9100,
      displayAnalog: 0,
      glassReplaceAnalog: 2300,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3000,
      cameraFrontOriginal: 3500,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 2000,
      chargingCable: 1900,
      microphone: 1900,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone Xs',
    service: {
      displayOriginal: 9200,
      displayAnalog: 0,
      glassReplaceAnalog: 2400,
      batteryOriginal: 2700,
      batteryAnalog: 2900,
      cameraMainOriginal: 4000,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 2000,
      chargingCable: 2500,
      microphone: 2500,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone Xs Max',
    service: {
      displayOriginal: 10700,
      displayAnalog: 0,
      glassReplaceAnalog: 3300,
      batteryOriginal: 2700,
      batteryAnalog: 2900,
      cameraMainOriginal: 4000,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 2500,
      chargingCable: 2500,
      microphone: 2500,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone Xr',
    service: {
      displayOriginal: 6700,
      displayAnalog: 0,
      glassReplaceAnalog: 2300,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3000,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 2000,
      chargingCable: 2500,
      microphone: 2500,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone 11',
    service: {
      displayOriginal: 6700,
      displayAnalog: 0,
      glassReplaceAnalog: 3300,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3400,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 2500,
      chargingCable: 2500,
      microphone: 0,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone 11 Pro',
    service: {
      displayOriginal: 9200,
      displayAnalog: 0,
      glassReplaceAnalog: 3600,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3700,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 2500,
      chargingCable: 0,
      microphone: 0,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone 11 Pro Max',
    service: {
      displayOriginal: 10700,
      displayAnalog: 0,
      glassReplaceAnalog: 3600,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3700,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 3000,
      chargingCable: 0,
      microphone: 0,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone SE 2',
    service: {
      displayOriginal: 5300,
      displayAnalog: 1400,
      glassReplaceAnalog: 1500,
      batteryOriginal: 1500,
      batteryAnalog: 600,
      cameraMainOriginal: 1900,
      cameraFrontOriginal: 900,
      motherboard: 0,
      cleaningAudioChannelsMesh: 250,
      cleaningAudioChannelsBoozer: 350,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 850,
      restoringTightness: 200,
      buttonPower: 700,
      speaker: 700,
      jackHeadphones: 700,
      body: 500,
      chargingCable: 900,
      microphone: 700,
      glassCameraAnalog: 0,
    }
  },
  {
    model: 'iPhone 12',
    service: {
      displayOriginal: 9200,
      displayAnalog: 0,
      glassReplaceAnalog: 5500,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3700,
      cameraFrontOriginal: 0,
      motherboard: 14100,
      cleaningAudioChannelsMesh: 700,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 5200,
      chargingCable: 0,
      microphone: 0,
      glassCameraAnalog: 0,
    }
  },
  {
    model: 'iPhone 12 mini',
    service: {
      displayOriginal: 7900,
      displayAnalog: 0,
      glassReplaceAnalog: 5500,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3700,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 700,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 5200,
      chargingCable: 0,
      microphone: 0,
      glassCameraAnalog: 0,
    }
  },
  {
    model: 'iPhone 12 Pro',
    service: {
      displayOriginal: 9200,
      displayAnalog: 0,
      glassReplaceAnalog: 5500,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 4100,
      cameraFrontOriginal: 0,
      motherboard: 17400,
      cleaningAudioChannelsMesh: 700,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 5500,
      chargingCable: 0,
      microphone: 0,
      glassCameraAnalog: 0,
    }
  },
  {
    model: 'iPhone 12 Pro Max',
    service: {
      displayOriginal: 10700,
      displayAnalog: 0,
      glassReplaceAnalog: 0,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 4100,
      cameraFrontOriginal: 0,
      motherboard: 20000,
      cleaningAudioChannelsMesh: 700,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 5500,
      chargingCable: 0,
      microphone: 0,
      glassCameraAnalog: 0,
    }
  },
]
