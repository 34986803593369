import {REPAIRIPHONE_ROUTE, ADMIN_ROUTE} from './const';
import {RepairiPhone} from '../page/RepairiPhone'
import {Admin} from '../page/Admin'

export const publicRoutes = [
  {
    path: REPAIRIPHONE_ROUTE,
    Component: <RepairiPhone/>
  },
  // {
  //   path: HOME_ROUTE,
  //   Component: <Home/>
  // }
];

export const authRoutes = [
  {
    path: ADMIN_ROUTE,
    Component: <Admin/>
  }
]