import React, {useEffect, useState} from 'react';
import avatarSmile from '../static/avatar-smile-80-1.png';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from "@mui/material";
import {databaseRepair} from '../store/store';

export const RepairiPhone = () => {
  const data = databaseRepair;

  const [models, setModels] = useState([]);
  const [model, setModel] = useState('');

  const [displayOriginal, setDisplayOriginal] = useState(0);
  const [displayOriginalState, setDisplayOriginalState] = useState(false);
  const [displayAnalog, setDisplayAnalog] = useState(0);
  const [displayAnalogState, setDisplayAnalogState] = useState(false);
  const [glassReplaceAnalog, setGlassReplaceAnalog] = useState(0);
  const [glassReplaceAnalogState, setGlassReplaceAnalogState] = useState(false);
  const [batteryOriginal, setBatteryOriginal] = useState(0);
  const [batteryOriginalState, setBatteryOriginalState] = useState(false);
  const [batteryAnalog, setBatteryAnalog] = useState(0);
  const [batteryAnalogState, setBatteryAnalogState] = useState(false);
  const [cameraMainOriginal, setCameraMainOriginal] = useState(0);
  const [cameraMainOriginalState, setCameraMainOriginalState] = useState(false);
  const [cameraFrontOriginal, setCameraFrontOriginal] = useState(0);
  const [cameraFrontOriginalState, setCameraFrontOriginalState] = useState(false);
  const [motherboard, setMotherboard] = useState(0);
  const [motherboardState, setMotherboardState] = useState(false);
  const [cleaningAudioChannelsMesh, setCleaningAudioChannelsMesh] = useState(0);
  const [cleaningAudioChannelsMeshState, setCleaningAudioChannelsMeshState] = useState(false);
  const [cleaningAudioChannelsBoozer, setCleaningAudioChannelsBoozer] = useState(0);
  const [cleaningAudioChannelsBoozerState, setCleaningAudioChannelsBoozerState] = useState(false);
  const [buttonHomeOriginal, setButtonHomeOriginal] = useState(0);
  const [buttonHomeOriginalState, setButtonHomeOriginalState] = useState(false);
  const [buttonHomeAnalog, setButtonHomeAnalog] = useState(0);
  const [buttonHomeAnalogState, setButtonHomeAnalogState] = useState(false);
  const [restoringTightness, setRestoringTightness] = useState(0);
  const [restoringTightnessState, setRestoringTightnessState] = useState(false);
  const [buttonPower, setButtonPower] = useState(0);
  const [buttonPowerState, setButtonPowerState] = useState(false);
  const [speaker, setSpeaker] = useState(0);
  const [speakerState, setSpeakerState] = useState(false);
  const [jackHeadphones, setJackHeadphones] = useState(0);
  const [jackHeadphonesState, setJackHeadphonesState] = useState(false)
  const [body, setBody] = useState(0);
  const [bodyState, setBodyState] = useState(false);
  const [chargingCable, setChargingCable] = useState(0);
  const [chargingCableState, setChargingCableState] = useState(false);
  const [microphone, setMicrophone] = useState(0);
  const [microphoneState, setMicrophoneState] = useState(false);
  const [glassCameraAnalog, setGlassCameraAnalog] = useState(0);
  const [glassCameraAnalogState, setGlassCameraAnalogState] = useState(false);

  const [classDisplayOriginal, setClassDisplayOriginal] = useState(false);
  const [classDisplayAnalog, setClassDisplayAnalog] = useState(false);
  const [classGlassReplaceAnalog, setClassGlassReplaceAnalog] = useState(false);
  const [classBatteryOriginal, setClassBatteryOriginal] = useState(false);
  const [classBatteryAnalog, setClassBatteryAnalog] = useState(false);
  const [classCameraMainOriginal, setClassCameraMainOriginal] = useState(false);
  const [classCameraFrontOriginal, setClassCameraFrontOriginal] = useState(false);
  const [classMotherboard, setClassMotherboard] = useState(false);
  const [classCleaningAudioChannelsMesh, setClassCleaningAudioChannelsMesh] = useState(false);
  const [classCleaningAudioChannelsBoozer, setClassCleaningAudioChannelsBoozer] = useState(false);
  const [classButtonHomeOriginal, setClassButtonHomeOriginal] = useState(false);
  const [classButtonHomeAnalog, setClassButtonHomeAnalog] = useState(false);
  const [classRestoringTightness, setClassRestoringTightness] = useState(false);
  const [classButtonPower, setClassButtonPower] = useState(false);
  const [classSpeaker, setClassSpeaker] = useState(false);
  const [classJackHeadphones, setClassJackHeadphones] = useState(false);
  const [classBody, setClassBody] = useState(false);
  const [classChargingCable, setClassChargingCable] = useState(false);
  const [classMicrophone, setClassMicrophone] = useState(false);
  const [classGlassCameraAnalog, setClassGlassCameraAnalog] = useState(false);

  const getPriceServiceByModel = (selectedModel, serviceItem) => {
    return data.filter(({model}) => model === selectedModel)[0].service[serviceItem];
  }

  const getDisplayOriginal = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.displayOriginal;
    return getPriceServiceByModel(selectedModel, 'displayOriginal');
  }

  const getDisplayAnalog = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.displayAnalog;
    return getPriceServiceByModel(selectedModel, 'displayAnalog');
  }

  const getGlassReplaceAnalog = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.glassReplaceAnalog;
    return getPriceServiceByModel(selectedModel, 'glassReplaceAnalog');
  }

  const getBatteryOriginal = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.batteryOriginal;
    return getPriceServiceByModel(selectedModel, 'batteryOriginal');
  }

  const getBatteryAnalog = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.batteryAnalog;
    return getPriceServiceByModel(selectedModel, 'batteryAnalog');
  }

  const getCameraMainOriginal = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.cameraMainOriginal;
    return getPriceServiceByModel(selectedModel, 'cameraMainOriginal');
  }

  const getCameraFrontOriginal = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.cameraFrontOriginal;
    return getPriceServiceByModel(selectedModel, 'cameraFrontOriginal');
  }

  const getMotherboard = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.motherboard;
    return getPriceServiceByModel(selectedModel, 'motherboard');
  }

  const getCleaningAudioChannelsMesh = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.cleaningAudioChannelsMesh;
    return getPriceServiceByModel(selectedModel, 'cleaningAudioChannelsMesh');
  }

  const getCleaningAudioChannelsBoozer = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.cleaningAudioChannelsBoozer;
    return getPriceServiceByModel(selectedModel, 'cleaningAudioChannelsBoozer');
  }

  const getButtonHomeOriginal = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.buttonHomeOriginal;
    return getPriceServiceByModel(selectedModel, 'buttonHomeOriginal');
  }

  const getButtonHomeAnalog = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.buttonHomeAnalog;
    return getPriceServiceByModel(selectedModel, 'buttonHomeAnalog');
  }

  const getRestoringTightness = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.restoringTightness;
    return getPriceServiceByModel(selectedModel, 'restoringTightness');
  }

  const getButtonPower = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.buttonPower;
    return getPriceServiceByModel(selectedModel, 'buttonPower');
  }

  const getSpeaker = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.speaker;
    return getPriceServiceByModel(selectedModel, 'speaker');
  }

  const getJackHeadphones = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.jackHeadphones;
    return getPriceServiceByModel(selectedModel, 'jackHeadphones');
  }

  const getBody = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.body;
    return getPriceServiceByModel(selectedModel, 'body');
  }

  const getChargingCable = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.chargingCable;
    return getPriceServiceByModel(selectedModel, 'chargingCable');
  }

  const getMicrophone = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.microphone;
    return getPriceServiceByModel(selectedModel, 'microphone');
  }

  const getGlassCameraAnalog = (selectedModel) => {
    // return data.filter(({model}) => model === selectedModel)[0].service.glassCameraAnalog;
    return getPriceServiceByModel(selectedModel, 'glassCameraAnalog');
  }

  useEffect(() => {
    const initialModel = data[0].model;
    setModels(data.map(item => item.model));
    setModel(initialModel);
    getAvailableItem(initialModel);
  }, []);


  const onSubmit = async (data) => {
    // try {
    console.table(data);
    const {
      model,

    } = data;

    const formData = new FormData();
    formData.append('model', model);
    let addText = '';
    // if (fineBatteryState) {
    //   let sep = addText === '' ? '' : ', ';
    //   addText = addText + sep + 'ємність батареї < 87%'
    // }
    // if (fineRepairState) {
    //   let sep = addText === '' ? '' : ', ';
    //   addText = addText + sep + 'проводився ремонт телефону'
    // }

    console.log('addText', addText);
    formData.append('addInfo', addText);

    // } catch (e) {
    //   console.log('onSubmit error', e.message);
    // }

  };

  const setDisplayOriginalByModel = (selectedModel) => {
    const value = getDisplayOriginal(selectedModel);
    setDisplayOriginal(value);
  }

  const setDisplayAnalogByModel = (selectedModel) => {
    const value = getDisplayAnalog(selectedModel);
    setDisplayAnalog(value);
  }

  const setGlassReplaceAnalogByModel = (selectedModel) => {
    const value = getGlassReplaceAnalog(selectedModel);
    setGlassReplaceAnalog(value);
  }

  const setBatteryOriginalByModel = (selectedModel) => {
    const value = getBatteryOriginal(selectedModel);
    setBatteryOriginal(value);
  }

  const setBatteryAnalogByModel = (selectedModel) => {
    const value = getBatteryAnalog(selectedModel);
    setBatteryAnalog(value);
  }

  const setCameraMainOriginalByModel = (selectedModel) => {
    const value = getCameraMainOriginal(selectedModel);
    setCameraMainOriginal(value);
  }

  const setCameraFrontOriginalByModel = (selectedModel) => {
    const value = getCameraFrontOriginal(selectedModel);
    setCameraFrontOriginal(value);
  }

  const setMotherboardByModel = (selectedModel) => {
    const value = getMotherboard(selectedModel);
    setMotherboard(value);
  }

  const setCleaningAudioChannelsMeshByModel = (selectedModel) => {
    const value = getCleaningAudioChannelsMesh(selectedModel);
    setCleaningAudioChannelsMesh(value);
  }

  const setCleaningAudioChannelsBoozerByModel = (selectedModel) => {
    const value = getCleaningAudioChannelsBoozer(selectedModel);
    setCleaningAudioChannelsBoozer(value);
  }

  const setButtonHomeOriginalByModel = (selectedModel) => {
    const value = getButtonHomeOriginal(selectedModel);
    setButtonHomeOriginal(value);
  }

  const setButtonHomeAnalogByModel = (selectedModel) => {
    const value = getButtonHomeAnalog(selectedModel);
    setButtonHomeAnalog(value);
  }

  const setRestoringTightnessByModel = (selectedModel) => {
    const value = getRestoringTightness(selectedModel);
    setRestoringTightness(value);
  }

  const setButtonPowerByModel = (selectedModel) => {
    const value = getButtonPower(selectedModel);
    setButtonPower(value);
  }

  const setSpeakerByModel = (selectedModel) => {
    const value = getSpeaker(selectedModel);
    setSpeaker(value);
  }

  const setJackHeadphonesByModel = (selectedModel) => {
    const value = getJackHeadphones(selectedModel);
    setJackHeadphones(value);
  }

  const setBodyByModel = (selectedModel) => {
    const value = getBody(selectedModel);
    setBody(value);
  }

  const setChargingCableByModel = (selectedModel) => {
    const value = getChargingCable(selectedModel);
    setChargingCable(value);
  }

  const setMicrophoneByModel = (selectedModel) => {
    const value = getMicrophone(selectedModel);
    setMicrophone(value);
  }

  const setGlassCameraAnalogByModel = (selectedModel) => {
    const value = getGlassCameraAnalog(selectedModel);
    setGlassCameraAnalog(value);
  }

  const setFineZero = (selectedModel) => {
    setDisplayOriginalState(false);
    setDisplayAnalogState(false);
    setGlassReplaceAnalogState(false);
    setBatteryOriginalState(false);
    setBatteryAnalogState(false);
    setCameraMainOriginalState(false);
    setCameraFrontOriginalState(false);
    setMotherboardState(false);
    setCleaningAudioChannelsMeshState(false);
    setCleaningAudioChannelsBoozerState(false);
    setButtonHomeOriginalState(false);
    setButtonHomeAnalogState(false);
    setRestoringTightnessState(false);
    setButtonPowerState(false);
    setSpeakerState(false);
    setJackHeadphonesState(false);
    setBodyState(false);
    setChargingCableState(false);
    setMicrophoneState(false);
    setGlassCameraAnalogState(false);
    setDisplayOriginal(0);
    setDisplayAnalog(0);
    setGlassReplaceAnalog(0);
    setBatteryOriginal(0);
    setBatteryAnalog(0);
    setCameraMainOriginal(0);
    setCameraFrontOriginal(0);
    setMotherboard(0);
    setCleaningAudioChannelsMesh(0);
    setCleaningAudioChannelsBoozer(0);
    setButtonHomeOriginal(0);
    setButtonHomeAnalog(0);
    setRestoringTightness(0);
    setButtonPower(0);
    setSpeaker(0);
    setJackHeadphones(0);
    setBody(0);
    setChargingCable(0);
    setMicrophone(0);
    setGlassCameraAnalog(0);
    getAvailableItem(selectedModel);
  }

  const getAvailableItem = (selectedModel) => {
    setClassDisplayOriginal(getPriceServiceByModel(selectedModel, 'displayOriginal') === 0);
    setClassDisplayAnalog(getPriceServiceByModel(selectedModel, 'displayAnalog') === 0);
    setClassGlassReplaceAnalog(getPriceServiceByModel(selectedModel, 'glassReplaceAnalog') === 0);
    setClassBatteryOriginal(getPriceServiceByModel(selectedModel, 'batteryOriginal') === 0);
    setClassBatteryAnalog(getPriceServiceByModel(selectedModel, 'batteryAnalog') === 0);
    setClassCameraMainOriginal(getPriceServiceByModel(selectedModel, 'cameraMainOriginal') === 0);
    setClassCameraFrontOriginal(getPriceServiceByModel(selectedModel, 'cameraFrontOriginal') === 0);
    setClassMotherboard(getPriceServiceByModel(selectedModel, 'motherboard') === 0);
    setClassCleaningAudioChannelsMesh(getPriceServiceByModel(selectedModel, 'cleaningAudioChannelsMesh') === 0);
    setClassCleaningAudioChannelsBoozer(getPriceServiceByModel(selectedModel, 'cleaningAudioChannelsBoozer') === 0);
    setClassButtonHomeOriginal(getPriceServiceByModel(selectedModel, 'buttonHomeOriginal') === 0);
    setClassButtonHomeAnalog(getPriceServiceByModel(selectedModel, 'buttonHomeAnalog') === 0);
    setClassRestoringTightness(getPriceServiceByModel(selectedModel, 'restoringTightness') === 0);
    setClassButtonPower(getPriceServiceByModel(selectedModel, 'buttonPower') === 0);
    setClassSpeaker(getPriceServiceByModel(selectedModel, 'speaker') === 0);
    setClassJackHeadphones(getPriceServiceByModel(selectedModel, 'jackHeadphones') === 0);
    setClassBody(getPriceServiceByModel(selectedModel, 'body') === 0);
    setClassChargingCable(getPriceServiceByModel(selectedModel, 'chargingCable') === 0);
    setClassMicrophone(getPriceServiceByModel(selectedModel, 'microphone') === 0);
    setClassGlassCameraAnalog(getPriceServiceByModel(selectedModel, 'glassCameraAnalog') === 0);
  }

  const handleChangeModel = (e) => {
    const {value} = e.target;
    setModel(value);
    setFineZero(value);
  }

  const handleChangeDisplayOriginalState = () => {
    setDisplayAnalogState(false);
    setDisplayAnalog(0);
    setGlassReplaceAnalogState(false);
    setGlassReplaceAnalog(0);
    setDisplayOriginalState(!displayOriginalState);
    !displayOriginalState
      ? setDisplayOriginalByModel(model)
      : setDisplayOriginal(0);
  }

  const handleChangeDisplayAnalogState = () => {
    setDisplayOriginalState(false);
    setDisplayOriginal(0);
    setGlassReplaceAnalogState(false);
    setGlassReplaceAnalog(0);
    setDisplayAnalogState(!displayAnalogState);
    !displayAnalogState
      ? setDisplayAnalogByModel(model)
      : setDisplayAnalog(0);
  }

  const handleChangeGlassReplaceAnalogState = () => {
    setDisplayAnalogState(false);
    setDisplayAnalog(0);
    setDisplayOriginalState(false);
    setDisplayOriginal(0);
    setGlassReplaceAnalogState(!glassReplaceAnalogState);
    !glassReplaceAnalogState
      ? setGlassReplaceAnalogByModel(model)
      : setGlassReplaceAnalog(0);
  }

  const handleChangeBatteryOriginalState = () => {
    setBatteryAnalogState(false);
    setBatteryAnalog(0);
    setBatteryOriginalState(!batteryOriginalState);
    !batteryOriginalState
      ? setBatteryOriginalByModel(model)
      : setBatteryOriginal(0);
  }

  const handleChangeBatteryAnalogState = () => {
    setBatteryOriginalState(false);
    setBatteryOriginal(0);
    setBatteryAnalogState(!batteryAnalogState);
    !batteryAnalogState
      ? setBatteryAnalogByModel(model)
      : setBatteryAnalog(0);
  }

  const handleChangeCameraMainOriginalState = () => {
    setCameraMainOriginalState(!cameraMainOriginalState);
    !cameraMainOriginalState
      ? setCameraMainOriginalByModel(model)
      : setCameraMainOriginal(0);
  }

  const handleChangeCameraFrontOriginalState = () => {
    setCameraFrontOriginalState(!cameraFrontOriginalState);
    !cameraFrontOriginalState
      ? setCameraFrontOriginalByModel(model)
      : setCameraFrontOriginal(0);
  }

  const handleChangeMotherboardState = () => {
    setMotherboardState(!motherboardState);
    !motherboardState
      ? setMotherboardByModel(model)
      : setMotherboard(0);
  }

  const handleChangeCleaningAudioChannelsMeshState = () => {
    setCleaningAudioChannelsMeshState(!cleaningAudioChannelsMeshState);
    !cleaningAudioChannelsMeshState
      ? setCleaningAudioChannelsMeshByModel(model)
      : setCleaningAudioChannelsMesh(0);
  }

  const handleChangeCleaningAudioChannelsBoozerState = () => {
    setCleaningAudioChannelsBoozerState(!cleaningAudioChannelsBoozerState);
    !cleaningAudioChannelsBoozerState
      ? setCleaningAudioChannelsBoozerByModel(model)
      : setCleaningAudioChannelsBoozer(0);
  }

  const handleChangeButtonHomeOriginalState = () => {
    setButtonHomeAnalogState(false);
    setButtonHomeAnalog(0);
    setButtonHomeOriginalState(!buttonHomeOriginalState);
    !buttonHomeOriginalState
      ? setButtonHomeOriginalByModel(model)
      : setButtonHomeOriginal(0);
  }

  const handleChangeButtonHomeAnalogState = () => {
    setButtonHomeOriginalState(false);
    setButtonHomeOriginal(0);
    setButtonHomeAnalogState(!buttonHomeAnalogState);
    !buttonHomeAnalogState
      ? setButtonHomeAnalogByModel(model)
      : setButtonHomeAnalog(0);
  }

  const handleChangeRestoringTightnessState = () => {
    setRestoringTightnessState(!restoringTightnessState);
    !restoringTightnessState
      ? setRestoringTightnessByModel(model)
      : setRestoringTightness(0);
  }

  const handleChangeButtonPowerState = () => {
    setButtonPowerState(!buttonPowerState);
    !buttonPowerState
      ? setButtonPowerByModel(model)
      : setButtonPower(0);
  }

  const handleChangeSpeakerState = () => {
    setSpeakerState(!speakerState);
    !speakerState
      ? setSpeakerByModel(model)
      : setSpeaker(0);
  }

  const handleChangeJackHeadphonesState = () => {
    setJackHeadphonesState(!jackHeadphonesState);
    !jackHeadphonesState
      ? setJackHeadphonesByModel(model)
      : setJackHeadphones(0);
  }

  const handleChangeBodyState = () => {
    setBodyState(!bodyState);
    !bodyState
      ? setBodyByModel(model)
      : setBody(0);
  }

  const handleChangeChargingCableState = () => {
    setChargingCableState(!chargingCableState);
    !chargingCableState
      ? setChargingCableByModel(model)
      : setChargingCable(0);
  }

  const handleChangeMicrophoneState = () => {
    setMicrophoneState(!microphoneState);
    !microphoneState
      ? setMicrophoneByModel(model)
      : setMicrophone(0);
  }

  const handleChangeGlassCameraAnalogState = () => {
    setGlassCameraAnalogState(!glassCameraAnalogState);
    !glassCameraAnalogState
      ? setGlassCameraAnalogByModel(model)
      : setGlassCameraAnalog(0);
  }

  return (
    <Paper
      sx={{
        padding: '1rem'
      }}
    >
      <Box sx={{m: 1}}>
        <form onSubmit={onSubmit}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '1.5rem',
          }}>

            <Box sx={{margin: '1rem 0 1.5rem'}}>
              <Typography variant="h5" component="h1">
                Сервіс iPhone
              </Typography>
            </Box>

            <Box sx={{
              border: '1px #71be00 solid',
              borderRadius: '6px',
              padding: '1rem 1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '300px',
            }}>

              <Box>
                <Typography sx={{
                  // border: '1px green solid',
                  fontWeight: 'bold'
                }} variant="h3" component="h1">
                  {
                    displayOriginal +
                    displayAnalog +
                    glassReplaceAnalog +
                    batteryOriginal +
                    batteryAnalog +
                    cameraMainOriginal +
                    cameraFrontOriginal +
                    motherboard +
                    cleaningAudioChannelsMesh +
                    cleaningAudioChannelsBoozer+
                    buttonHomeOriginal +
                    buttonHomeAnalog +
                    restoringTightness +
                    buttonPower +
                    speaker +
                    jackHeadphones +
                    body +
                    chargingCable +
                    microphone +
                    glassCameraAnalog
                  }
                </Typography>
              </Box>

              <Box sx={{ml: 1}}>
                <Typography sx={{
                  // border: '1px green solid',
                }} variant="h4" component="h1">
                  ₴
                </Typography>
              </Box>

              <Box sx={{
                ml: 1,
                // border: '1px solid red'
              }}>
                {
                    <img src={avatarSmile} alt="avatar"/>
                }
              </Box>
            </Box>
          </Box>

          <FormControl variant="standard" fullWidth>
            <InputLabel id="label-model">Модель</InputLabel>
            <Select
              labelId="label-model"
              id="id-model"
              name={'model'}
              value={model}
              label="Телефон"
              onChange={handleChangeModel}
              sx={{
                fontSize: '1.8rem',
                mb: '1.5rem'
              }}
            >
              {models.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Сервіс:</FormLabel>
            <FormGroup
              sx={{
                mb: '1.5rem'
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classDisplayOriginal}
                    name={'displayOriginalState'}
                    checked={displayOriginalState}
                    onChange={handleChangeDisplayOriginalState}
                  />
                }
                label="Заміна дисплею (оригінал)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classDisplayAnalog}
                    name={'displayAnalogState'}
                    checked={displayAnalogState}
                    onChange={handleChangeDisplayAnalogState}

                  />
                }
                label="Заміна дисплею (аналог)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classGlassReplaceAnalog}
                    name={'glassReplaceAnalogState'}
                    checked={glassReplaceAnalogState}
                    onChange={handleChangeGlassReplaceAnalogState}
                  />
                }
                label="Заміна скла (аналог)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classBatteryOriginal}
                    name={'batteryOriginalState'}
                    checked={batteryOriginalState}
                    onChange={handleChangeBatteryOriginalState}
                  />
                }
                label="Заміна батареї (оригінал)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classBatteryAnalog}
                    name={'batteryAnalogState'}
                    checked={batteryAnalogState}
                    onChange={handleChangeBatteryAnalogState}
                  />
                }
                label="Заміна батареї (аналог)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classCameraMainOriginal}
                    name={'cameraMainOriginalState'}
                    checked={cameraMainOriginalState}
                    onChange={handleChangeCameraMainOriginalState}
                  />
                }
                label="Заміна камери (оригінал)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classCameraFrontOriginal}
                    name={'cameraFrontOriginalState'}
                    checked={cameraFrontOriginalState}
                    onChange={handleChangeCameraFrontOriginalState}
                  />
                }
                label="Заміна фронтальної камери (оригінал)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classMotherboard}
                    name={'motherboardState'}
                    checked={motherboardState}
                    onChange={handleChangeMotherboardState}
                  />
                }
                label="Заміна системної плати"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classCleaningAudioChannelsMesh}
                    name={'cleaningAudioChannelsMeshState'}
                    checked={cleaningAudioChannelsMeshState}
                    onChange={handleChangeCleaningAudioChannelsMeshState}
                  />
                }
                label="Чистка аудіоканалів (сітка)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classCleaningAudioChannelsBoozer}
                    name={'cleaningAudioChannelsBoozerState'}
                    checked={cleaningAudioChannelsBoozerState}
                    onChange={handleChangeCleaningAudioChannelsBoozerState}
                  />
                }
                label="Чистка аудіоканалів (бузер)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classButtonHomeOriginal}
                    name={'buttonHomeOriginalState'}
                    checked={buttonHomeOriginalState}
                    onChange={handleChangeButtonHomeOriginalState}
                  />
                }
                label="Заміна кнопки Home (оригінал)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classButtonHomeAnalog}
                    name={'buttonHomeAnalogState'}
                    checked={buttonHomeAnalogState}
                    onChange={handleChangeButtonHomeAnalogState}
                  />
                }
                label="Заміна кнопки Home (аналог)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classRestoringTightness}
                    name={'restoringTightnessState'}
                    checked={restoringTightnessState}
                    onChange={handleChangeRestoringTightnessState}
                  />
                }
                label="Відновлення герметичності"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classButtonPower}
                    name={'buttonPowerState'}
                    checked={buttonPowerState}
                    onChange={handleChangeButtonPowerState}
                  />
                }
                label="Заміна кнопки Power"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classSpeaker}
                    name={'speakerState'}
                    checked={speakerState}
                    onChange={handleChangeSpeakerState}
                  />
                }
                label="Заміна динаміку"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classJackHeadphones}
                    name={'jackHeadphonesState'}
                    checked={jackHeadphonesState}
                    onChange={handleChangeJackHeadphonesState}
                  />
                }
                label="Заміна jack навушників"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classBody}
                    name={'bodyState'}
                    checked={bodyState}
                    onChange={handleChangeBodyState}
                  />
                }
                label="Заміна корпусу"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classChargingCable}
                    name={'chargingCableState'}
                    checked={chargingCableState}
                    onChange={handleChangeChargingCableState}
                  />
                }
                label="Заміна шлейфу зарядки"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classMicrophone}
                    name={'microphoneState'}
                    checked={microphoneState}
                    onChange={handleChangeMicrophoneState}
                  />
                }
                label="Заміна мікрофону"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={classGlassCameraAnalog}
                    name={'glassCameraAnalogState'}
                    checked={glassCameraAnalogState}
                    onChange={handleChangeGlassCameraAnalogState}
                  />
                }
                label="Заміна скла на камері (аналог)"
              />
            </FormGroup>

            <Box sx={{mb: '0.5rem', display: 'flex', justifyContent: 'center'}}>
              <Button variant="outlined" color="success">
                <a href={'tel:+380988338058'} style={{color: '#71be00', textDecoration: 'none'}}> Зв'язок з Экспертом з
                  ремонту</a>
              </Button>
            </Box>

            <FormHelperText sx={{textAlign: 'justify'}}>
                При виборі сервісної позиції, з заміни того чи іншого модулю на оригінальний (від компанії Apple), надається гарантія впродовж 1 року, з моменту встановлення.
              <br/><br/>
                Так само враховуйте, що незважаючи на те, що калькулятор має доволі низьку похибку, точну вартість сервісу може визначити лише експерт з ремонту А-Сервіс.
            </FormHelperText>
          </FormControl>

        </form>
      </Box>
    </Paper>
  );
};

